import React from 'react'
import styled from '@emotion/styled'
import Container from '../components/shared/container'
import { mediaQueries } from '../theme'
import RegistrationComponent from '../components/auth/registration'
import Layout from '../components/layout'
import PageMetadata from '../components/page-metadata'

const InnerContainer = styled(Container)`
  padding: 0;
  margin-bottom: ${p => p.theme.space[18]};

  ${mediaQueries.lg} {
    margin-bottom: ${p => p.theme.space[29]};
  }
`

const Registration = styled(RegistrationComponent)`
  width: 21.875rem;
  margin: 0 auto;
  ${mediaQueries.sm} {
    width: 31.25rem;
  }
`

const StartPage = ({ location }) => {
  const next = new URLSearchParams(location.search).get('next')

  return (
    <Layout location={location}>
      <PageMetadata
        title="Start Your Journey with Getform by Creating an Account"
        description="Register a Getform account within seconds and create your first shareable online form today. "
        keywords="create getform account"
      />
      <InnerContainer>
        <Registration next={next} />
      </InnerContainer>
    </Layout>
  )
}

export default StartPage
