import React from 'react'
import styled from '@emotion/styled'
import { mediaQueries } from '../../theme'
import Divider from '../shared/divider'
import Link from '../shared/link'
import { GoogleButton } from './social-buttons'
import RegistrationFormComponent from './registration-form'
import LoginComponent from './login'

const RegistrationForm = styled(RegistrationFormComponent)`
  margin-bottom: ${p => p.theme.space[8]};
`

const Note = styled.p`
  max-width: 16.5rem;
  margin: 0 auto;
  font-size: ${p => p.theme.fontSizes[1]};
  text-align: center;
`

const GoogleSignUpButton = styled(GoogleButton)`
  width: 100%;
`

const Title = styled.h1`
  font-size: ${p => p.theme.fontSizes[5]};
  text-align: center;
  margin: ${p => `0 0 ${p.theme.space[6]} 0`};
  ${mediaQueries.sm} {
    font-size: ${p => p.theme.fontSizes[7]};
    margin: ${p => `0 0 ${p.theme.space[8]} 0`};
  }
`

const Container = styled.div`
  box-shadow: ${p => p.theme.shadows.overlay};
`

const Content = styled.div`
  padding: ${p => `${p.theme.space[8]} ${p.theme.space[6]}`};
  border-top: 1px solid ${p => p.theme.colors.form.border};
  ${mediaQueries.sm} {
    padding: ${p =>
      `${p.theme.space[12]} ${p.theme.space[17]} ${p.theme.space[15]}`};
  }
`

const Login = styled(LoginComponent)`
  text-align: center;
  padding: ${p => p.theme.space[3]} 0;
`

const Registration = ({ className, next }) => (
  <Container className={className}>
    <Login next={next} />
    <Content>
      <Title>Create an account</Title>
      <GoogleSignUpButton next={next}>Sign up with Google</GoogleSignUpButton>
      <Divider>OR</Divider>
      <RegistrationForm next={next} />
      <Note>
        By signing up, you agree to the{' '}
        <Link variant="fill" to="/terms/">
          Terms of service
        </Link>{' '}
        and{' '}
        <Link variant="fill" to="/privacy/">
          Privacy policy
        </Link>
      </Note>
    </Content>
  </Container>
)

export default Registration
